body {
  margin: 0;
  background-color: #f7f7f7;
}




@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.hero-section-introduction{
  display: flex;
  flex-direction: row;
  height: 93vh;
  
}

.hero-section-introduction h2{
    color: #002057;
    font-size: 5rem;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    
    font-size: 60px;
    
}

.hero-section-introduction p span{
  color: #1e90ff;
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  font-size: 30px;
}

.hero-section-introduction p {
  margin-top: 0;
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  padding-top: 0.5rem;
  
  font-size: 30px;
}

.hero-social{
  margin-top: 1rem;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  
}

.icon-social{
  width: 50px;
  padding-right: 0.9rem;
  margin-top: 1.7rem;
}

.icon-social:hover{
  cursor: pointer;
  filter: opacity(21%);
}

.icon-social-footer:hover{
  cursor: pointer;
  filter: opacity(21%);
}


.hero-title{
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8%;
}

.hero-image{
display: flex;
align-items: center;

width: 60%;
}

.hero-image img{
    margin-left: 7%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    cursor: pointer;
    height: 50%;
    width: 50%;
    border-radius: 50%; /* Mantém a imagem circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), /* Sombra suave */
                0 6px 20px rgba(0, 0, 0, 0.1); /* Efeito de profundidade */
    transition: transform 0.3s ease; /* Animação ao passar o mouse */
}

.hero-image img:hover {
  transform: scale(1.05); /* Leve aumento no tamanho ao passar o mouse */
}

.about-me{
text-align: center;
}

.about-me h2 {
  
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  padding-top: 0.5rem;
  
  font-size: 25px;
  margin-bottom: 2rem;
}

.about-me h2 span{
  color: #1e90ff;
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  font-size: 25px;
  margin-left: 0.2em;
}


.skills{
  text-align: center;
  }
  
  .skills h2 {
    
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    padding-top: 0.5rem;
    
    font-size: 25px;
  }
  
  .skills h2 span{
    color: #1e90ff;
    font-family: Poppins, sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    font-size: 25px;
    margin-left: 0.2em;
  }


  .projects{
    text-align: center;
    }
    
    .projects h2 {
      
      color: #000;
      font-family: Poppins, sans-serif;
      font-size: 2.5rem;
      font-weight: 600;
      padding-top: 0.5rem;
      
      font-size: 25px;
    }
    
    .projects h2 span{
      color: #1e90ff;
      font-family: Poppins, sans-serif;
      font-size: 2.5rem;
      font-weight: 600;
      font-size: 25px;
      margin-left: 0.2em;
    }

    .my-projects{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .project-card{
      width: 20%;
      height: fit-content;
      border: 2px solid #ddd;
      border-radius: 4px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), /* Sombra suave */
                0 6px 20px rgba(0, 0, 0, 0.1); /* Efeito de profundidade */
      transition: transform 0.3s ease; /* Animação ao passar o mouse */
      
    }

    .project-card:hover{
      transform: scale(1.05); /* Leve aumento no tamanho ao passar o mouse */
      cursor: pointer;
    }

    .project-card img{
      width: 100%;
      height: 30vh;
    }

    .project-info{
      
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .project-name{
      color: #1e90ff;
      font-family: Poppins, sans-serif;
      margin-left: 1rem;
      margin-bottom: 0;

    }

    .project-description{
      margin-left: 1rem;
      font-family: Poppins, sans-serif;
      font-size: 13px;
      text-align: left;
    }
    
    .awards{
      text-align: center;
      padding-bottom: 5rem;
      

    }
      
      .awards h2 {
        
        color: #000;
        font-family: Poppins, sans-serif;
        font-size: 2.5rem;
        font-weight: 600;
        padding-top: 0.5rem;
        
        font-size: 25px;
      }
      
      .awards h2 span{
        color: #1e90ff;
        font-family: Poppins, sans-serif;
        font-size: 2.5rem;
        font-weight: 600;
        font-size: 25px;
        margin-left: 0.2em;
      }
    
      .awards .certifications  a{
        
        height: 27vh;
        display: flex;
        width: fit-content;
        transition: transform 0.3s ease; /* Animação ao passar o mouse */
        
        
        
      }

      .awards .certifications  a:hover{
        transform: scale(1.05);
        
      }

      .awards .certifications {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        
        
        
      }



      .footer{
        width: 100%;
        display: flex;
        align-items: center;
        height: 15vh;
        background-color: white;
      }


      .footer-bottom{
          width:60%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
          
          
      }

     .copyright{
      color: #000;
      font-family: Nunito;
      font-size: 15px;
      line-height: 24px;
      
     }

     .social-footer img{
      
      padding-right: 0.5rem;
      width: 2.7rem;
      
     }

     .social-footer{
      
      height: fit-content;
      
     }

     .content-wrapper {
      height: 80vh;
      display: flex;
      flex-direction: row;
     
    }

    .profile-img{
      display: flex;
      justify-content: center;
      width: 60%;
      height: 100%;
      
    }
    
    .profile-img img{
      border-radius: 8px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
    
    
    
    .profile-info{
      display: flex;
      flex-direction: column;
      text-align: left;
      height: 100%;
      font-family: Poppins, sans-serif;
      
    }

    .profile-info h3{
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 0;
      color: #333;
      margin-top: 0;
    }
    

    .profile-info .job-title{
      color: #1e90ff;
    }

    .resume-btn {
  
      display: flex;
      width: fit-content;
      padding: 10px 20px;
      font-size: 18px;
      color: #fff;
      background-color: #007bff;
      border-radius: 50px;
      text-decoration: none;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease, transform 0.3s ease;
      
      margin-top: 2rem;
  }
  
  .resume-btn:hover {
      background-color: #0056b3;
      transform: scale(1.05);
  }

    .contact-info strong{
      color: #1e90ff;
    }

    .contact-info{
        display: flex;
        flex-direction: row;
        width: fit-content;
        
    }

    .info-direita{
      margin-left: 5rem;
    }

    .email {
      color: #333;
      text-decoration: none;
    }

    .nome{
      font-size:25px;
      
    }
    
h2{
  margin-top: 3.8rem;
}

.project-card a{
  text-decoration: none;
}

.project-card a p{
  text-decoration: none;
  color: #000;
}
    
     @media (max-width: 767px) {

      .hero-section-introduction{
        height: 100%;
      }
      .hero-image{
        display:none;
        }

        .hero-title h2{
          font-size: 49px;
        }

        .hero-title p {
          font-size: 15px;
        }

        .hero-section-introduction p span{ 
          font-size: 15px;
        }

        .icon-social{
          width: 45px;
          
        }

        .hero-social{
         width: 57%;
        }

      .footer-bottom{
        width:100%;
        flex-direction: column;
        align-items: center;
      }

      .copyright{
        color: #000;
        font-family: Nunito;
        font-size: 13px;
        line-height: 24px;
        margin-top: 2rem;
       }
       
       .footer{
        height:fit-content;
        width: 100%;
      
       }

       .awards{
        padding-bottom: 2.5rem;
        
  
        }

       

        .content-wrapper{
          flex-direction: column;
          height: 100%;
          
        }
        
        .profile-img{
          width: 100%;
          margin-bottom: 2em;
        }

        .profile-img img{
          width: 50%;
        }

        .description{
          text-align: center;
          font-size: 15px;
        }
        
        .resume-btn{
          align-self: center;
        }

        .profile-info{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          
        }

        .contact-info{
            display: flex;
            flex-direction: column;
            width: 100%;
            
        }

        .contact-info .info-esquerda{
          display: flex;
          flex-direction: row;
          width: 100%;
          font-size: 14px;
          justify-content: center;
          
      }

      .contact-info .info-direita{
        display: flex;
        flex-direction: row;
        width: 100%;
        font-size: 14px;
        justify-content: center;
        
    }

    
    .info-direita{
      margin-left: 0rem;
    }

    .social-footer img{
      
      padding-right: 0.5rem;
      padding-bottom: 1rem;
      width: 2.4rem;
      
     }

     .awards .certifications  a{
        
      height: 20vh;
      display: flex;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      
    }
        
     }



     @media (min-width: 1540px) {
      .content-wrapper{
        height: 60vh;
      }

      .profile-img{
        display: flex;
        justify-content: center;
        width: 37%;
        height: 100%;
      }

      .profile-info{
        width: 60%;
      }

      .resume-btn {
        margin-top: 2.4rem;
      }
      
      .contact-info{
        margin-top: 1rem;
        
    }

    


     }



     @media (max-width: 1024px) {
      .hero-image{
        display: none;
      }

      .hero-title h2{
        font-size: 90px;
      }

      .hero-title{
        width: 100%;
      }

      .hero-title p {
        font-size: 35px;
      }

      .hero-section-introduction p span{ 
      
        font-size: 35px;
      }

      .icon-social{
        width: 70px;
        
      }

      .hero-social{
        
        width: fit-content;
      }
     
      .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
      }

      .profile-img{
          width: 90%;
      }

      .profile-info{
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95%;
      }

      .awards .certifications  a{
        
        height: 20vh;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        
      }
    }
     @media (max-width: 768px) {
      .project-card {
        width: 50%; /* Aumenta o tamanho para 50% da tela */
      }

      .hero-title h2{
        font-size: 60px;
      }

      .hero-title{
        width: 100%;
      }

      .hero-title p {
        font-size: 25px;
      }
      
      
      .hero-section-introduction p span{ 
      
        font-size: 25px;
      }

      .icon-social{
        width: 50px;
        margin: 0;
        
      }

      .awards .certifications  a{
        
        height: 20vh;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
      }

      .awards .certifications  a{
        
        height: 20vh;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        
      }
    }
    
    /* Quando a largura da tela for menor que 480px (ex. telemóveis) */
    @media (max-width: 480px) {
      .project-card {
        width: 80%; /* Aumenta o tamanho para 80% da tela */
      }

      .awards .certifications  a{
        
        height: 15vh;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        
      }
    }


