@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar{
    background-color: white;
}

.navbar-container{
    height: 7vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.navbar-nav{
    display: flex;
    list-style: none;
    flex-direction: row;
}

.navbar-logo{
    color:#0e2431;
    font-size: 1.4rem;
    font-weight: 800;
    margin-left: 8%;
    text-decoration: none;
    font-family: Poppins, sans-serif;
}


.nav-item{
    margin-left: 1.5rem;
   
}


.nav-item a{
    font-style: normal;
    color:black ;
    font-family: Poppins, sans-serif;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 0.2em;
}

header .navbar ul li a.active, header .navbar ul li a:hover {
    border-bottom: .17rem solid #011aff;
    
}

header .navbar ul li a:hover{
    color:#011aff;
}


@media (max-width: 767px) {
    .navbar-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
    
        
    }

    .nav-item a{
        font-style: normal;
        color:black ;
        font-family: Poppins, sans-serif;
        text-decoration: none;
        font-weight: 500;
        font-size: 0.75rem;
        
        
    
    }

    .nav-item{
        
        padding: auto;
        margin: auto;
    }

    .navbar-menu-collapse{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        
        
        
    }

    .navbar-nav{
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
       
        
    }
    
    .navbar-logo{
        margin-top: 0.4rem;
        font-size: 1.4rem;
        font-weight: 800;
        margin-left: 0;
        margin-bottom: 0;
    }
}