.skill-section {
    margin: 0 auto;
    max-width: 100%;
  }
  
  .skill-category {
    margin-bottom: 24px;
    margin-top: 2rem;
  }
  
  .skill-category h2 {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    font-size: 1.5em;
    text-align: left;
  }
  
  .skill-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px; /* Espaçamento entre os cartões */
  }
  
  .skill-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    align-items: center;
    font-family: Poppins, sans-serif;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Animação ao passar o mouse */
    
  }

  .skill-card:hover{
    transform: scale(1.05); /* Leve aumento no tamanho ao passar o mouse */
      cursor: pointer;
  }

  .skill-card img{
    width: 70px;
  }

  .skill-card p{
    margin-left: 1.3rem;
    font-size: 17px;
  }

 /* Estilos para telas menores */

 @media (max-width: 1370px) {
  .skill-card {
    flex: 1 1 calc(33.33% - 16px); /* 3 cartões por linha em telas médias */
    
    max-width: 192px;
  }
}

@media (max-width: 1024px) {
  .skill-card {
    flex: 1 1 calc(33.33% - 16px); /* 3 cartões por linha em telas médias */
  }
}

@media (max-width: 768px) {
  .skill-card {
    flex: 1 1 calc(50% - 16px); /* 2 cartões por linha em tablets */
    max-width: 182px;
  }
}

@media (max-width: 480px) {
  .skill-card {
    flex: 1 1 calc(50% - 16px); /* 1 cartão por linha em smartphones */
    max-width: 140px;
  }

  .skill-card img{
    width: 50px;
  }

  .skill-card p{
    margin-left: 1rem;
    font-size: 14px;
  }
}